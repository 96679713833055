#wpadminbar #wp-admin-bar-my-sites>.ab-item,
#wpadminbar #wp-admin-bar-site-name>.ab-item {
    padding-left: 40px
}

#dn_option-contact_maps img {
    max-width: none
}

#wpadminbar #wp-admin-bar-my-sites>.ab-item:before,
#wpadminbar #wp-admin-bar-site-name>.ab-item:before {
    content: ''!important;
    width: 35px;
    height: 28px;
    background-image: url(./img/static/site-logo.png)!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-color: transparent!important;
    z-index: 10;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 2px;
    left: 2px
}

#dashboard_right_now li.comment-count {
    display: none!important
}

#digital_noir_instruction_metabox ul {
    list-style: disc;
    margin-left: 0.5em;
    padding-left: 0.8em;
    line-height: 1.2em
}

#digital_noir_instruction_metabox .inside h2,
#digital_noir_instruction_metabox .inside h3,
#digital_noir_instruction_metabox .inside h4 {
    font-weight: bold;
    font-size: 18px;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0px;
    padding-bottom: 5px
}

#digital_noir_instruction_metabox .inside h3 {
    font-size: 16px;
}

#digital_noir_instruction_metabox .inside h4 {
    font-size: 14px
}

#digital_noir_instruction_metabox .inside p {
    margin-top: 0
}

#digital_noir_instruction_metabox a {
    text-decoration: underline
}

#adminmenu .wp-has-current-submenu .wp-submenu .wp-submenu-head,
#adminmenu .wp-menu-arrow,
#adminmenu .wp-menu-arrow div,
#adminmenu li.current a.menu-top,
#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
.folded #adminmenu li.current.menu-top,
.folded #adminmenu li.wp-has-current-submenu {
    background: $dn-primary-color;
}

#adminmenu li.menu-top:hover,
#adminmenu li.opensub>a.menu-top,
#adminmenu li>a.menu-top:focus,
#adminmenu .wp-submenu a:focus,
#adminmenu .wp-submenu a:hover,
#adminmenu a:hover,
#adminmenu li.menu-top>a:focus,
#adminmenu li a:focus div.wp-menu-image:before,
#adminmenu li.opensub div.wp-menu-image:before,
#adminmenu li:hover div.wp-menu-image:before {
    color: $dn-primary-color;
}

// Hides customise menu under appearance (disabled through PHP)
.hide-if-no-customize {
    display: none;
}

body.roles-administrator {
    .hide-if-no-customize {
        display: block;
    }
}

/////////////////////
// ACF Block stuff //
/////////////////////
.acf-fc-popup .preview {
    position: absolute;
    right: 100%;
    margin-right: 0px;
    bottom: 0;
    background: #383c44;
    min-height: 100%;
    border-radius: 5px;
    align-content: center;
    display: grid;
}

.acf-fc-popup .preview .inner-preview {
    padding: 10px;
}

.acf-fc-popup .preview img {
    display: block;
}

.acf-flexible-content .layout .acf-fc-layout-handle {
    background-color: $dn-primary-color;
    color: #fff!important
}

.acf-repeater .acf-actions {
    text-align: center
}

.postbox.acf-postbox h2 {
    padding: 15px 20px !important;
    color: #fff;
    background: $dn-primary-color;
}

// Clean up ACF & Wordpress buttons
.wp-core-ui .button-primary {
    background: $dn-primary-color;
    border-color: darken( $dn-primary-color, 5%) darken( $dn-primary-color, 10%) darken( $dn-primary-color, 10%);
    -webkit-box-shadow: 0 1px 0 darken( $dn-primary-color, 10%);
    box-shadow: 0 1px 0 darken( $dn-primary-color, 10%);
    color: #fff;
    text-decoration: none;
    text-shadow: 0 -1px 1px darken( $dn-primary-color, 10%), 1px 0 1px darken( $dn-primary-color, 10%), 0 1px 1px darken( $dn-primary-color, 10%), -1px 0 1px darken( $dn-primary-color, 10%);
    &.focus,
    &.hover,
    &:focus,
    &:hover {
        background: lighten( $dn-primary-color, 3%);
        border-color: darken( $dn-primary-color, 10%);
        color: #fff;
    }
    &.active,
    &:active {
        background: darken( $dn-primary-color, 5%);
        border-color: darken( $dn-primary-color, 10%);
        -webkit-box-shadow: inset 0 2px 0 darken( $dn-primary-color, 10%);
        box-shadow: inset 0 2px 0 darken( $dn-primary-color, 10%);
        vertical-align: top;
    }
}

.acf-repeater .acf-actions {
    text-align: right;
}

.wp-core-ui .button-primary-disabled,
.wp-core-ui .button-primary.disabled,
.wp-core-ui .button-primary:disabled,
.wp-core-ui .button-primary[disabled] {
    color: lighten( $dn-primary-color, 12.5%)!important;
    background: lighten( $dn-primary-color, 10%)!important;
    border-color: lighten( $dn-primary-color, 7.5%)!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .1)!important;
    cursor: default;
    color: white!important;
}

// Gallery back-end styling
.acf-gallery-side-inner .compat-field-gallery_video {
    display: table-row!important
}

.compat-field-gallery_video {
    display: none!important;
}

/////////////////////
// ACF Focal Point //
/////////////////////
@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }
    .mfp-container {
        //   padding-left: $mfp-popup-padding-left-mobile;
        //   padding-right: $mfp-popup-padding-left-mobile;
    }
}

.acf-focal_point-canvas {
    width: 100%;
    cursor: crosshair;
    min-width: 150px;
    min-height: 150px;
    background-color: #eaeaea;
}

.acf-focal_point-image {
    display: none;
}

.acf-focal_point .has-image {
    display: none;
    position: relative;
}

.acf-focal_point .acf-button-delete {
    position: absolute;
    top: -11px;
    right: -11px;
    z-index: 1;
    cursor: pointer;
}

.acf-focal_point .acf-icon {
    opacity: 0;
    transition: .25s ease-in-out;
}

.acf-focal_point .acf-icon:hover {
    color: #D54E21;
}

.acf-focal_point .has-image:hover .acf-icon {
    opacity: 1;
}

.acf-focal_point.active .no-image {
    display: none;
}

.acf-focal_point.active .has-image {
    display: block;
    float: left;
}

.thermo-custom-attributes {
    clear: none!important;
    width: 25%!important;
    padding-right: 10px;
    margin: 0 0 10px 0;
    box-sizing: border-box;
    @include bp(1400px) {
        width: 50%!important;
    }
}
.woocommerce_variable_attributes p[class*="variable_stock_status"]{
    display: none!important
}

.wp-admin.post-type-quote {
    #titlediv,
    #post-body #normal-sortables {
        display: none 
    }
    .sender-detail {
        th {
            text-align: left;
            width: 100px
        }
    }
    .quote-request.admin-template {
        table {
            width: 100%;
            font-size: 16px;
            th {
                color: #fff;
                background: #000
            }
            border-left:1px solid #eaeaea;
            border-top:1px solid #eaeaea;
            td,
            th {
                border-bottom: 1px solid #eaeaea;
                border-right: 1px solid #eaeaea;
                padding: 7px;
            }
        }
    }
}

.stock_status_field.hide_if_variable.hide_if_external.form-field._stock_status_field,
select[name="stock_status"] {
    display: none!important
}

.variable_stock_status0_field{
    display: none!important
}


// Magnificent pop-up
////////////////////////
//      Settings      //
////////////////////////
// overlay
$mfp-overlay-color: #0b0b0b !default; // Color of overlay screen
$mfp-overlay-opacity: 0.8 !default; // Opacity of overlay screen
$mfp-shadow: 0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe
// spacing
$mfp-popup-padding-left: 8px !default; // Padding from left and from right side
$mfp-popup-padding-left-mobile: 6px !default; // Same as above, but is applied when width of window is less than 800px
$mfp-z-index-base: 100000 !default; // Base z-index of popup
// controls
$mfp-include-arrows: true !default; // Include styles for nav arrows
$mfp-controls-opacity: 0.65 !default; // Opacity of controls
$mfp-controls-color: #FFF !default; // Color of controls
$mfp-controls-border-color: #3F3F3F !default; // Border color of controls
$mfp-inner-close-icon-color: #333 !default; // Color of close button when inside
$mfp-controls-text-color: #CCC !default; // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover: #FFF !default; // Hover color of preloader and "1 of X" indicator
// Iframe-type options
$mfp-include-iframe-type: true !default; // Enable Iframe-type popups
$mfp-iframe-padding-top: 40px !default; // Iframe padding top
$mfp-iframe-background: #000 !default; // Background color of iframes
$mfp-iframe-max-width: 900px !default; // Maximum width of iframes
$mfp-iframe-ratio: 9/16 !default; // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)
// Image-type options
$mfp-include-image-type: true !default; // Enable Image-type popups
$mfp-image-background: #444 !default;
$mfp-image-padding-top: 40px !default; // Image padding top
$mfp-image-padding-bottom: 40px !default; // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default; // Removes paddings from top and bottom
// Image caption options
$mfp-caption-title-color: #F3F3F3 !default; // Caption title color
$mfp-caption-subtitle-color: #BDBDBD !default; // Caption subtitle color
// A11y
$mfp-use-visuallyhidden: false !default; // Hide content from browsers, but make it available for screen readers

/* Magnific Popup CSS */

////////////////////////
//
// Contents:
//
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////
////////////////////////
// 1. General styles
////////////////////////
// Transluscent overlay
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $mfp-z-index-base + 2;
    overflow: hidden;
    position: fixed;
    background: $mfp-overlay-color;
    opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $mfp-z-index-base + 3;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 $mfp-popup-padding-left;
    box-sizing: border-box;
}

// Vertical centerer helper
.mfp-container {
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top {
    .mfp-container {
        &:before {
            display: none;
        }
    }
}

// Popup content holder
.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: $mfp-z-index-base + 5;
}

.mfp-inline-holder,
.mfp-ajax-holder {
    .mfp-content {
        width: 100%;
        cursor: auto;
    }
}

// Cursors
.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur {
    &,
    .mfp-image-holder .mfp-close {
        cursor: -moz-zoom-out;
        cursor: -webkit-zoom-out;
        cursor: zoom-out;
    }
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor {
    .mfp-content {
        cursor: auto;
    }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

// Hide the image during the loading
.mfp-loading {
    &.mfp-figure {
        display: none;
    }
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden {
    // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
    .mfp-hide {
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }
}

@else {
    .mfp-hide {
        display: none !important;
    }
}

////////////////////////
// 2. Appearance
////////////////////////
// Preloader and text that displays error messages
.mfp-preloader {
    color: $mfp-controls-text-color;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: $mfp-z-index-base + 4;
    a {
        color: $mfp-controls-text-color;
        &:hover {
            color: $mfp-controls-text-color-hover;
        }
    }
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
    .mfp-preloader {
        display: none;
    }
}

// Hide content when it was not loaded
.mfp-s-error {
    .mfp-content {
        display: none;
    }
}

// CSS-reset for buttons
button {
    &.mfp-close,
    &.mfp-arrow {
        overflow: visible;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        display: block;
        outline: none;
        padding: 0;
        z-index: $mfp-z-index-base + 6;
        box-shadow: none;
        touch-action: manipulation;
    }
    &::-moz-focus-inner {
        padding: 0;
        border: 0
    }
}

// Close icon
.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: $mfp-controls-opacity;
    padding: 0 0 18px 10px;
    color: $mfp-controls-color;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
    &:hover,
    &:focus {
        opacity: 1;
    }
    &:active {
        top: 1px;
    }
}

.mfp-close-btn-in {
    .mfp-close {
        color: $mfp-inner-close-icon-color;
    }
}

.mfp-image-holder,
.mfp-iframe-holder {
    .mfp-close {
        color: $mfp-controls-color;
        right: -6px;
        text-align: right;
        padding-right: 6px;
        width: 100%;
    }
}

// "1 of X" counter
.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: $mfp-controls-text-color;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

// Navigation arrows
@if $mfp-include-arrows {
    .mfp-arrow {
        position: absolute;
        opacity: $mfp-controls-opacity;
        margin: 0;
        top: 50%;
        margin-top: -55px;
        padding: 0;
        width: 90px;
        height: 110px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &:active {
            margin-top: -54px;
        }
        &:hover,
        &:focus {
            opacity: 1;
        }
        &:before,
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 35px;
            margin-left: 35px;
            border: medium inset transparent;
        }
        &:after {
            border-top-width: 13px;
            border-bottom-width: 13px;
            top: 8px;
        }
        &:before {
            border-top-width: 21px;
            border-bottom-width: 21px;
            opacity: 0.7;
        }
    }
    .mfp-arrow-left {
        left: 0;
        &:after {
            border-right: 17px solid $mfp-controls-color;
            margin-left: 31px;
        }
        &:before {
            margin-left: 25px;
            border-right: 27px solid $mfp-controls-border-color;
        }
    }
    .mfp-arrow-right {
        right: 0;
        &:after {
            border-left: 17px solid $mfp-controls-color;
            margin-left: 39px
        }
        &:before {
            border-left: 27px solid $mfp-controls-border-color;
        }
    }
}

// Iframe content type
@if $mfp-include-iframe-type {
    .mfp-iframe-holder {
        padding-top: $mfp-iframe-padding-top;
        padding-bottom: $mfp-iframe-padding-top;
        .mfp-content {
            line-height: 0;
            width: 100%;
            max-width: $mfp-iframe-max-width;
        }
        .mfp-close {
            top: -40px;
        }
    }
    .mfp-iframe-scaler {
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: $mfp-iframe-ratio * 100%;
        iframe {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $mfp-iframe-background;
        }
    }
}

// Image content type
@if $mfp-include-image-type {
    /* Main image in popup */
    img {
        &.mfp-img {
            width: auto;
            max-width: 100%;
            height: auto;
            display: block;
            line-height: 0;
            box-sizing: border-box;
            padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
            margin: 0 auto;
        }
    }
    /* The shadow behind the image */
    .mfp-figure {
        line-height: 0;
        
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: $mfp-image-padding-top;
            bottom: $mfp-image-padding-bottom;
            display: block;
            right: 0;
            width: auto;
            height: auto;
            z-index: -1;
            box-shadow: $mfp-shadow;
            background: $mfp-image-background;
        }
        small {
            color: $mfp-caption-subtitle-color;
            display: block;
            font-size: 12px;
            line-height: 14px;
        }
        figure {
            margin: 0;
        }
    }
    .mfp-bottom-bar {
        margin-top: -$mfp-image-padding-bottom + 4;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        cursor: auto;
    }
    .mfp-title {
        text-align: left;
        line-height: 18px;
        color: $mfp-caption-title-color;
        word-wrap: break-word;
        padding-right: 36px; // leave some space for counter at right side
    }
    .mfp-image-holder {
        .mfp-content {
            max-width: 100%;
        }
    }
    .mfp-gallery {
        .mfp-image-holder {
            .mfp-figure {
                cursor: pointer;
            }
        }
    }
    @if $mfp-include-mobile-layout-for-image {
        @media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
            /**
       * Remove all paddings around the image on small screen
       */
            .mfp-img-mobile {
                .mfp-image-holder {
                    padding-left: 0;
                    padding-right: 0;
                }
                img {
                    &.mfp-img {
                        padding: 0;
                    }
                }
                .mfp-figure {
                    // The shadow behind the image
                    &:after {
                        top: 0;
                        bottom: 0;
                    }
                    small {
                        display: inline;
                        margin-left: 5px;
                    }
                }
                .mfp-bottom-bar {
                    background: rgba(0, 0, 0, 0.6);
                    bottom: 0;
                    margin: 0;
                    top: auto;
                    padding: 3px 5px;
                    position: fixed;
                    box-sizing: border-box;
                    &:empty {
                        padding: 0;
                    }
                }
                .mfp-counter {
                    right: 5px;
                    top: 3px;
                }
                .mfp-close {
                    top: 0;
                    right: 0;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: rgba(0, 0, 0, 0.6);
                    position: fixed;
                    text-align: center;
                    padding: 0;
                }
            }
        }
    }
}

// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }
    .mfp-container {
        padding-left: $mfp-popup-padding-left-mobile;
        padding-right: $mfp-popup-padding-left-mobile;
    }
}

.the-table{
    tr td{
        transition:all .3s ease-in-out;
    }
    tr.flash td{
        background-color: #d8f9b8
    }
}

.form-hss{
        max-width: 800px;
        margin: 0 auto;
        padding: 30px;
        background:#fff;
        position: relative;
        &.loading{
            &:before{
                content: '';
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(255,255,255,.5);
                z-index: 555;
            }
        }
        .label{
            padding-left: 85px;
            margin-bottom: 15px;
            position: relative;
            span{
                font-weight: bold;
                position: absolute;
                top: 0;
                left: 0;;
            }
        }
        
    .inner{
        
        .fieldset{
            margin-bottom: 15px;
        }
        label{
            font-weight: bold;
            margin-bottom: 5px;
            display: inline-block;
        }
        textarea{
            width: 100%;
            height: 160px;
        }
    }
}