// Colours
$dn-text-color: #0f0f0f;
$dn-bg-color: #ddd;
$dn-bg-color-2: #eee;
$dn-primary-color: #fe8601;
$dn-secondary-color: #fb9e36;
$dn-success-color: #45af4a;
$dn-fail-color: #bf1827;
$dn-form-element-color: #c1c1c1;
$green: #4fae29;
$red: #ae2929;

$flooring-color: #fe8601;
$rails-color: #02abe9;
$heating-color: #189c4c;
$mirror-color: #203f9d; 

$flooring-hover-color: #fd9b2f;
$rails-hover-color: #2cbbf0;
$heating-hover-color: #37aa64;
$mirror-hover-color: #2c4baa; 

$placeholder-color: #c2c2c2;

@-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }