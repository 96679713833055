// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
    font-size: ($sizeValue * 16) * 1px;
    font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Clearfix
@mixin clearfix() {
    content: "";
    display: table;
    table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
    clear: both;
}

@mixin radius($rad : 50%){
	-webkit-border-radius: $rad;
	-moz-border-radius: $rad;
	border-radius: $rad; 
}

@mixin translateY($trans:0%){
	transform: translateY($trans);
	-moz-transform: translateY($trans);
	-o-transform: translateY($trans);
	-webkit-transform: translateY($trans);
	-ms-transform: translateY($trans);
}

@mixin translateX($trans:0%){
	transform: translateX($trans);
	-moz-transform: translateX($trans);
	-o-transform: translateX($trans);
	-webkit-transform: translateX($trans);
	-ms-transform: translateX($trans);
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin transition($transition: all $main-transition-time $main-transition-type){
    transition: $transition;
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
}

@mixin bp($point) {
    @media (max-width: $point) {
        @content;
    }
}

@mixin bpm($point) {
    @media (min-width: $point) {
        @content;
    }
}

@mixin bp-between($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin bph($point) {
    @media (max-height: $point) {
        @content;
    }
}

@mixin radius($point){
    -webkit-border-radius: $point;
    -moz-border-radius: $point;
    border-radius: $point;
}

@mixin after(){
    content: '';
    display: table;
    width: 100%;
}

@mixin absolute(){
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}